import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from 'components/App'
import store from 'redux/store'
import 'index.css'
import { fetchAllBooksThunk } from 'redux/slices/booksSlice'
import reportWebVitals from 'reportWebVitals'
import { fetchCredentialThunk } from 'redux/slices/authSlice'
import { fetchAllAuthorsThunk } from 'redux/slices/authorsSlice'

store.dispatch(fetchAllBooksThunk())
store.dispatch(fetchAllAuthorsThunk())
store.dispatch(fetchCredentialThunk())

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
